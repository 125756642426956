import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaRegEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const OpenBankingList = () => {
    const [bankList, setBankList] = useState([]);
    const [token, setToken] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const rows = ["COMPANY NAME", "CREATED", "Req.", "Funded", "TYPE", "STATUS", "F. Manager", "ACTION"];

    //step 1
    const fetchToken = async () => {
        const url = `${process.env.REACT_APP_SERVER_URL}/gocardless-token`;
        try {
            const response = await fetch(url, {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();
            localStorage.setItem('bankToken', responseData?.access)
            setToken(responseData?.access);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchAllRequisitions = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/gocardless-requisitions/GETALL`, {
                token
            });
            setBankList(response.data.results);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchToken();
    }, []);

    useEffect(() => {
        fetchAllRequisitions();
    }, [token])

    const deleteRequisition = async (requisitionId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/gocardless-requisitions/delete/${requisitionId}`, { token });
            console.log('Requisition deleted successfully:', response.data);
        } catch (error) {
            setError(error.response.data);
            console.error('Error:', error);
        }
    };

    return (
        <div className='mt-5'>
            <div className='mt-5 mb-20 rounded-lg bg-base-200'>
                <div className="overflow-x-auto p-2">
                    <table className="table p-5">
                        <thead>
                            <tr>
                                {
                                    rows.map((item, i) => <th key={i} scope="col">
                                        <p className='flex justify-start items-center gap-2'>{item}</p>
                                    </th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {bankList &&
                                bankList.map((item, i) => <tr key={i}>
                                    <td className='font-[500]'>
                                        {item["business-name"]}
                                    </td>
                                    <td >
                                        {new Date(item?.createdDate).toLocaleDateString('en-UK', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric'
                                        })}
                                    </td>
                                    <td >
                                        {item["country"]} {parseInt(item["required-amount"]).toLocaleString('en-US', { style: 'decimal', useGrouping: true })}
                                    </td>
                                    <td>
                                        {item["country"]}  {parseInt(item["funded-amount"]).toString() !== "NaN"
                                            ? parseInt(item["funded-amount"]).toLocaleString('en-US', { style: 'decimal', useGrouping: true })
                                            : '0'}
                                    </td>
                                    <td >
                                        {item["type"]}
                                    </td>
                                    <td>
                                        {item["status"] || 'Initial Review'}
                                    </td>
                                    <td>
                                        {item["funding-manager"] || 'Pending'}
                                    </td>
                                    <td>
                                        {/* <Link className='button !bg-gray-50 !shadow-none !text-black hover:!bg-primary hover:!text-white !text-xl' to={`/admin/dashboard/edit-loan/${item?._id}`}><FaRegEye /></Link> */}
                                        <button className='button' onClick={()=>deleteRequisition(item.id)}>Delete</button>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
