import React from 'react';
import oops from './../../assets/Oops.svg'
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className='h-screen flex justify-center items-center flex-col bg-base-100'>
      <img src={oops} alt="Error Page" className='w-[30%] mx-auto' />
      <Link to='/admin/kanaban'><button className='button'>Go Home</button></Link>
    </div>
  )
}

export default ErrorPage
