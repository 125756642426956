import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import {AiOutlineArrowLeft} from 'react-icons/ai'
import { handleAdminRoute } from '../../functions/functions'
import { AuthContext } from '../../context/AuthContext'

const RequestLoan = () => {
    const {adminData} = useContext(AuthContext);
    return (
        <div>
            <Link to={`${handleAdminRoute(adminData)}`} className='btn mt-5 bg-white text-gray-500' style={{"boxShadow": "rgba(0, 0, 0, 0.05) 0px 20px 27px 0px"}}><AiOutlineArrowLeft />Go Back</Link>
            <form className='h-[70vh] flex items-center'>
                <div className='grid grid-cols-3 gap-2 w-[40%] m-auto'>
                    <Link to={`${handleAdminRoute(adminData)}/request-loan/mca`} className="button">MCA</Link>
                    <Link to={`${handleAdminRoute(adminData)}/request-loan/business-loan`} className="button">Business Loan</Link>
                    {/* <Link to={`${handleAdminRoute(adminData)}/request-loan/invoice-finance`} className="button">Invoice Finance</Link> */}
                    <Link to={`${handleAdminRoute(adminData)}/request-loan/grants`} className="button">Grants</Link>
                    {/* <button className="button">Renewal Loan</button>
                    <button className="button">Renewal MCA</button> */}
                </div>
            </form>
        </div>
    )
}

export default RequestLoan
