import { RouterProvider } from 'react-router-dom';
import './App.css';
import { router } from './routes/PublicRoute/PublicRoute';
import { QueryClient, QueryClientProvider } from 'react-query';

function App() {
    const queryClient = new QueryClient()

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router}></RouterProvider>
            </QueryClientProvider>
        </div>
    );
}

export default App;
