import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import { BeatLoader, PacmanLoader } from 'react-spinners';

const AdminRoute = ({ children }) => {
    const { loading, adminData, user, logOut, setAdminData } = useContext(AuthContext);
    const [tokenLoading, setTokenLoading] = useState(true);
    const token = localStorage.getItem('token');
    const location = useLocation();


    useEffect(() => {
        const fetchData = async () => {
            if (!loading && user?.uid) {
                try {
                    const tokenResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/token-varification`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
                        body: JSON.stringify({ email: user?.email })
                    });
                    const tokenData = await tokenResponse.json();

                    if (tokenData === false) {
                        logOut();
                    } else {
                        const adminResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/verify`, {
                            method: 'POST',
                            headers: { 'content-type': 'application/json' },
                            body: JSON.stringify({ email: user?.email })
                        });
                        const adminData = await adminResponse.json();
                        setAdminData(adminData);
                        if (adminData?.role === "super-admin" || adminData?.role === "admin") {
                            setTokenLoading(false);
                        } else {
                            setTokenLoading(false);
                            logOut();
                        }
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setAdminData(null);
                    setTokenLoading(false);
                }
            } else if (!loading && !user) {
                setTokenLoading(false);
            }
        };

        fetchData();

    }, [loading, user, location.pathname]);

    // if (!loading && user?.uid) {
    //     fetch(`https://final-server-zeta.vercel.app/token-varification`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json', authorization: `Bearer ${localStorage.getItem('token')}` },
    //         body: JSON.stringify({ email: user?.email })
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data === false) {
    //                 logOut();
    //             } else {
    //                 fetch('https://final-server-zeta.vercel.app/verify', {
    //                     method: 'POST',
    //                     headers: {
    //                         'content-type': 'application/json'
    //                     },
    //                     body: JSON.stringify({ email: user?.email })
    //                 })
    //                     .then(response => response.json())
    //                     .then(data => {
    //                         setAdminData(data);
    //                         if (data?.role === "super-admin" || data?.role === "admin") {
    //                             return children;
    //                         }
    //                     })
    //                     .catch(error => {
    //                         console.error('Error fetching admin data:', error);
    //                         setAdminData(null);
    //                         setTokenLoading(false);
    //                     })
    //             }
    //         })
    // } else if (!loading && !user) {
    //     setTokenLoading(false);
    // }

    // if (user && token) {
    //     setTokenLoading(true);
    //     fetch(`https://final-server-zeta.vercel.app/token-varification`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json', authorization: `Bearer ${localStorage.getItem('token')}` },
    //         body: JSON.stringify({ email: user?.email })
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data === false) {
    //                 logOut();
    //             } else {
    //                 fetch('https://final-server-zeta.vercel.app/verify', {
    //                     method: 'POST',
    //                     headers: {
    //                         'content-type': 'application/json'
    //                     },
    //                     body: JSON.stringify({ email: user?.email })
    //                 })
    //                     .then(response => response.json())
    //                     .then(data => {
    //                         setAdminData(data);
    //                         setTokenLoading(false);
    //                     })
    //                     .catch(error => {
    //                         console.error('Error fetching admin data:', error);
    //                         setAdminData(null);
    //                         setTokenLoading(false);
    //                     })
    //             }
    //         })
    // } else if (!loading) {
    //     setTokenLoading(false);
    //     logOut();
    // }

    if (loading || tokenLoading) {
        return (
            <div className='font-bold text-4xl flex mt-[45vh] bg-base-100 justify-center items-center'>
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    if (adminData?.role === "super-admin" || adminData?.role === "admin") {
        return children;
    }

    return <Navigate to="/admin/login" />;
}

export default AdminRoute