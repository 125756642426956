import React from 'react'

const ThankYou = () => {
  return (
    <div data-theme='light' className='w-full h-screen flex flex-col justify-center items-center'>
        <p className='text-5xl font-bold'>Thank You</p>
        {/* <p >One of our agent will be in contact with </p> */}
    </div>
  )
}

export default ThankYou