import React, { useContext, useEffect, useState } from 'react'
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from '../../firebase/firebase.config';
import ViewLoan from '../../components/ViewLoan/ViewLoan';
import EditLoan from '../../components/EditLoan/EditLoan';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { FaRegEye } from 'react-icons/fa';
import LoanPdfList from '../../components/LoanPdfList/LoanPdfList';
import { Link } from 'react-router-dom';
import { PacmanLoader } from 'react-spinners';
import { IoMdSearch } from "react-icons/io";
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';

const CallBackList = () => {
    const serverURL = `${process.env.REACT_APP_SERVER_URL}`;
    const { adminData, user, country } = useContext(AuthContext);
    const [dealList, setDealList] = useState([]);
    // const [loanList, setLoanList] = useState();
    const loanList = dealList.filter(e => e?.callBack);
    const [viewLoan, setViewLoan] = useState({});
    const [editLoan, setEditLoan] = useState({});
    const [refresh, setRefresh] = useState(true);
    const [loading, setLoading] = useState(true);
    const [pdflist, setPdflist] = useState([]);
    const [sortByDateState, setSortByDateState] = useState(false);
    const [sortByFundedAmount, setSortByFundedAmount] = useState(false);
    const [sortByRequiredAmount, setSortByRequiredAmount] = useState(false);
    const [searchRequestValue, setSearchRequestValue] = useState({});
    const [page, setPage] = useState(1); // Initialize page state
    const [limit, setLimit] = useState(10000); // Initialize limit state
    const [totalPages, setTotalPages] = useState(1);

    function convertTextToDate(text) {
        // Split the text into date and time parts
        console.log(text);
        const [datePart, timePart, am] = text.split(' ');
        // Split the date part into day, month, and year
        const [day, month, year] = datePart.split('-');

        // Split the time part into hours, minutes, and AM/PM
        const [hours, minutes] = timePart.split(':');
        const isAM = am === 'AM';

        // Adjust hours for 12-hour format
        let hourNum = parseInt(hours, 10);
        if (hourNum === 12) {
            hourNum = isAM ? 0 : 12;
        } else {
            hourNum += isAM ? 0 : 12;
        }

        // Create a new Date object
        const date = new Date(year, month - 1, day, hourNum, minutes);

        return date;
    }

    const sortByCallBackDateAndTime = (array) => {
        return array.sort((a, b) => {
            // Parse the callBack dates
            const dateTimeA = convertTextToDate(a.callBack.date + ' ' + a.callBack.time);
            const dateTimeB = convertTextToDate(b.callBack.date + ' ' + b.callBack.time);

            // Compare dates
            if (dateTimeA < dateTimeB) {
                return -1;
            }
            if (dateTimeA > dateTimeB) {
                return 1;
            }
            return 0;
        });
    }

    sortByCallBackDateAndTime(loanList);

    const closeModal = (type) => {
        const dialog = document.getElementById(type);
        dialog.close();
        setEditLoan({})
    }

    const handlePdfLinkClick = (e, item) => {
        e.preventDefault(); // Prevent the default behavior of the anchor element

        getDownloadURL(item)
            .then((url) => {
                // Once you have the URL, open it in a new tab/window
                window.open(url, '_blank');
            })
            .catch((error) => {
                console.error('Error getting download URL:', error);
            });
    };

    const sortDataByDate = () => {
        setSortByDateState(true)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateA - dateB;
        })
        setDealList(sortedData)
    };

    const sortDataByDateRevarse = () => {
        setSortByDateState(false)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB - dateA;
        })
        setDealList(sortedData)
    };

    const sortDataByFundedAmount = () => {
        setSortByFundedAmount(true)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["funded-amount"] || 0;
            const dateB = b["funded-amount"] || 0;
            return parseInt(dateA) - parseInt(dateB);
        })
        setDealList(sortedData)
    };
    const sortDataByFundedAmountReverse = () => {
        setSortByFundedAmount(false)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["funded-amount"] || 0;
            const dateB = b["funded-amount"] || 0;
            return parseInt(dateB) - parseInt(dateA);
        })
        setDealList(sortedData)
    };

    const sortDataByRequirements = () => {
        setSortByRequiredAmount(true)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["required-amount"] || 0;
            const dateB = b["required-amount"] || 0;
            return parseInt(dateA) - parseInt(dateB);
        })
        setDealList(sortedData)
    };
    const sortDataByRequirementsReverse = () => {
        setSortByRequiredAmount(false)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["required-amount"] || 0;
            const dateB = b["required-amount"] || 0;
            return parseInt(dateB) - parseInt(dateA);
        })
        setDealList(sortedData)
    };

    const updateSearchRequestValues = (e) => {
        setSearchRequestValue({
            ...searchRequestValue,
            [e.target.name]: e.target.value
        })
    }

    const updateNameSearchRequestValues = (e) => {
        e.preventDefault();
        // console.log(e.target["business-name"].value);
        setSearchRequestValue({
            ...searchRequestValue,
            "business-name": e.target["business-name"].value
        })
    }

    useEffect(() => {
        if (user) {
            const userEmail = user?.email;
            const userRole = adminData?.role;

            if (userRole === "super-admin") {
                axios.post(`${process.env.REACT_APP_SERVER_URL}/super-admin/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "smit@readysteadyfinance.co.uk") {
                axios.post(`${serverURL}/smit/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "jon@readysteadyfinance.co.uk") {
                axios.post(`${serverURL}/jon/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "jessicava@ariesbizph.com") {
                axios.post(`${serverURL}/Jessica/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "sheilapalmava@ariesbizph.com") {
                axios.post(`${serverURL}/Sheila/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "ganadosbechercarl@gmail.com") {
                axios.post(`${serverURL}/Becher/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "don@sedgefunding.co.uk") {
                axios.post(`${serverURL}/Don/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "carlsragay@gmail.com") {
                axios.post(`${serverURL}/Carl/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "info@ariesbizph.com") {
                axios.post(`${serverURL}/Chloe/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "millie@sedgefunding.co.uk") {
                axios.post(`${serverURL}/millie/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "don@sedgefunding.co.uk") {
                axios.post(`${serverURL}/don/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "mark@sedgefunding.co.uk") {
                axios.post(`${serverURL}/mark/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "myca@sedgefunding.co.uk") {
                axios.post(`${serverURL}/myca/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else if (userEmail === "vanessa@sedgefunding.co.uk") {
                axios.post(`${serverURL}/vanessa/loan-request/GETALL`, {
                    email: user.email,
                    searchQuery: searchRequestValue // Ensure the searchQuery object is structured correctly
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        return response.data;
                    })
                    .then(data => {
                        setDealList(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
        }

    }, [user, adminData, searchRequestValue])

    const rows = ["COMPANY NAME", "TYPE", "STATUS", "F. Manager", "Call Back", "ACTION"];

    const handlePageChange = newPage => {
        setPage(newPage);
    };

    const deleteCallBackEntry = async (id) => {
        setLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/update-callback/${id}`, { email: user?.email }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        setDealList(loanList.filter(item => item._id !== id))
        console.log(response);
        setLoading(false);
    }

    if (loading) {
        return <div className='text-4xl mt-[35vh] flex  justify-center items-center'>
            <PacmanLoader color="#e63a27" />
        </div>
    }

    return (
        <div className='mt-5'>
            <div className='flex justify-end items-center'>
                <div>
                    <button onClick={() => setSearchRequestValue({})} className="button mr-3">Clear Filter</button>
                    {/* <Link to={'/admin/dashboard/request-loan'} className='button'>Request Finance</Link> */}
                    <div className="join">
                        <button className="join-item btn" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>«</button>
                        <button className="join-item btn">{`Page ${page}`}</button>
                        <button className="join-item btn" onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>»</button>
                    </div>

                </div>
            </div>
            <div className='mt-5 mb-20 rounded-lg bg-base-200'>
                <div className="overflow-x-auto p-2">
                    <table className="table p-5">
                        <thead>
                            <tr>
                                {
                                    rows.map((item, i) => <th key={i} scope="col">
                                        {
                                            (item === "Funded" && item !== "CREATED") ? (sortByFundedAmount === false ? <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByFundedAmount} className='cursor-pointer'><BiSolidDownArrow /></span></p> : <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByFundedAmountReverse} className='cursor-pointer'><BiSolidUpArrow /></span></p>) : ((item !== "CREATED" && item !== "Req.") && item)
                                        }

                                        {
                                            item === "CREATED" && (sortByDateState === false ? <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByDate} className='cursor-pointer'><BiSolidDownArrow /></span></p> : <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByDateRevarse} className='cursor-pointer'><BiSolidUpArrow /></span></p>)
                                        }

                                        {
                                            item === "Req." && (sortByRequiredAmount === false ? <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByRequirements} className='cursor-pointer'><BiSolidDownArrow /></span></p> : <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByRequirementsReverse} className='cursor-pointer'><BiSolidUpArrow /></span></p>)
                                        }
                                    </th>)
                                }
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                {
                                    rows.map((item, i) => <th key={i} scope="col">
                                        {
                                            item === "COMPANY NAME" && <form onSubmit={(e) => updateNameSearchRequestValues(e)} className='flex'><input name='business-name' type="text" className="text-xs w-full border-2 p-2 font-normal" /><button type='submit' className='text-white text-xl ml-2 p-2 bg-primary rounded'><IoMdSearch /></button></form>
                                        }

                                        {
                                            item === "STATUS" && <select onChange={(e) => updateSearchRequestValues(e)} name='status' className="text-xs border-2 p-2 font-normal">
                                                <option value="all" defaultValue>Status</option>
                                                <option value="Initial Review">Initial Review</option>
                                                <option value="Additional Documents">Additional Documents</option>
                                                <option value="Golden Data">Golden Data</option>
                                                <option value="The Great Takeover">The Great Takeover</option>
                                                <option value="Pre-Underwriting">Pre-Underwriting</option>
                                                <option value="Pre-Offer">Pre-Offer</option>
                                                <option value="Underwriting">Underwriting</option>
                                                <option value="Extra docs ob">Extra docs ob</option>
                                                <option value="Awaiting Offer">Awaiting Offer</option>
                                                <option value="Offer">Offer</option>
                                                <option value="Contract Sent">Contract Sent</option>
                                                <option value="Awaiting Funding">Awaiting Funding</option>
                                                <option value="Last Chance">Last Chance</option>
                                                <option value="Funded This Month">Funded This Month</option>
                                                <option value="Funded">Funded</option>
                                                <option value="Last Chance">Last Chance</option>
                                                <option value="Cancelled">Cancelled</option>
                                                <option value="Cancelled for ever">Cancelled for ever</option>
                                            </select>
                                        }
                                        {
                                            item === "F. Manager" && <select name='funding-manager' onChange={(e) => updateSearchRequestValues(e)} className="text-xs border-2 p-2 font-normal">
                                                <option value="all" defaultValue>F. Manager</option>
                                                <option value="Pending" >Pending</option>
                                                <option value="Wayne">Wayne</option>
                                                <option value="Peter">Peter</option>
                                                <option value="Smit">Smit</option>
                                                {/* <option value="Dapo">Dapo</option> */}
                                                <option value="Jon">Jon</option>
                                                <option value="Millie">Millie</option>
                                                {/* <option value="Tan">Tan</option> */}
                                                {/* <option value="Don">Don</option> */}
                                                <option value="Mark">Mark</option>
                                                <option value="Myca">Myca</option>
                                                {/* <option value="Vanessa">Vanessa</option> */}
                                            </select>
                                        }
                                        {
                                            item === "TYPE" && <select name='type' onChange={(e) => updateSearchRequestValues(e)} className="text-xs border-2 p-2 font-normal">
                                                <option value="all" defaultValue>Type</option>
                                                <option value="MCA">MCA</option>
                                                <option value="Business Loan">Business Loan</option>
                                                <option value="Grants">Grants</option>
                                                <option value="Renewal Loan">Renewal Loan</option>
                                                <option value="Renewal MCA">Renewal MCA</option>
                                                <option value="New Lead">New Lead</option>
                                                <option value="YouLend">YouLend</option>
                                                <option value="Liberis">Liberis</option>
                                                <option value="365">365</option>
                                                <option value="Invoice">Invoice</option>
                                                <option value="Seneca">Seneca</option>
                                                <option value="Funding Alt">Funding Alt</option>
                                                <option value="Swiftfund">Swiftfund</option>
                                                <option value="Maxcap">Maxcap</option>
                                                <option value="Funding Circle">Funding Circle</option>
                                                <option value="Sedge">Sedge</option>
                                                <option value="IWOCA">IWOCA</option>
                                                <option value="Capify">Capify</option>
                                            </select>
                                        }
                                    </th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {loanList &&
                                loanList.map((item, i) => <tr key={i}>
                                    <td className='font-[500]'>
                                        {item["business-name"]}
                                    </td>

                                    <td >
                                        {item["type"]}
                                    </td>
                                    <td>
                                        {item["status"] || 'Initial Review'}
                                    </td>
                                    <td>
                                        {item["funding-manager"] || 'Pending'}
                                    </td>
                                    <td>
                                        <p>{new Date(item?.callBack?.date).toLocaleDateString('en-UK', { day: 'numeric', month: 'numeric', year: 'numeric', }) + ' ,' + item?.callBack?.time}</p>
                                    </td>
                                    <td>
                                        <button onClick={() => deleteCallBackEntry(item?._id)} className='button mr-3'>Complete</button>
                                        <Link className='button !bg-gray-50 !shadow-none !text-black hover:!bg-primary hover:!text-white !text-xl' to={`/admin/dashboard/edit-loan/${item?._id}`}><FaRegEye /></Link>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>


            {/* popup */}
            <dialog id="editLoan" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                    <EditLoan item={editLoan} setRefresh={setRefresh} refresh={refresh} closeModal={closeModal} />
                </div>
            </dialog>
            <dialog id="loanPdfList" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[500px] max-w-[500px] p-10">
                    <LoanPdfList pdflist={pdflist} handlePdfLinkClick={handlePdfLinkClick} />
                    <button type='button' onClick={() => closeModal("loanPdfList")} className="mt-3 button !text-[#000] !bg-[#f2f2f2] !rounded">Close</button>
                </div>
            </dialog>
            <dialog id="viewLoan" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <form method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                    <ViewLoan item={viewLoan} />
                    <div className="mt-3 text-right">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                    </div>
                </form>
            </dialog>
        </div>
    )
}

export default CallBackList