import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { HiTrash } from 'react-icons/hi';

const AllStatus = () => {
    const [allStatus, setAllStatus] = useState([]);

    const rows = ["Status", "Position", "Created Date"];

    const { createAccount, updateUserInfo, user, adminData } = useContext(AuthContext);


    const createNewStatus = (event) => {
        event.preventDefault();
        const statusInfo = event.target;
        const statusName = statusInfo.name.value;
        const createdBy = user.displayName;
        const position = allStatus.length;
        const createdDate = new Date().toLocaleString('en-GB', { timeZone: 'europe/London', hour12: true });
        const dialog = document.getElementById("my_modal_1");


        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/new-status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ statusName, createdBy, createdDate, position, email: user.email })
        })
            .then(response => response.json())
            .then(result => {
                dialog.close();
                setAllStatus([...allStatus, { statusName, createdBy, createdDate, position }])
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/all-status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ email: user.email })
        })
            .then(response => response.json())
            .then(data => {
                // Call a function to handle the loan data
                setAllStatus(data);
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle the error or display an error message
            });
    }, [])

    return (
        <div className='mt-5'>
            <div className='flex justify-end items-center'>
                {/* <input onChange={(e) => searchByName(e)} name='search' type="text" placeholder="Search" className="input w-72" /> */}
                <div>
                    <button onClick={() => window.my_modal_1.showModal()} className="button">Add New Status</button>
                </div>
            </div>
            <div className='mt-5 mb-20 rounded-lg bg-base-200 overflow-auto'>
                <div className="overflow-x-auto">
                    <table className="table">
                        {/* head */}
                        <thead>
                            <tr>
                                {
                                    rows.map((item, i) => <th key={i} scope="col" className='uppercase'>
                                        {item}
                                    </th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allStatus.map((item, i) => <tr key={i}>
                                    <th scope="row">
                                        {item["statusName"]}
                                    </th>

                                    <td >
                                        {item["position"] + 1}
                                    </td>
                                    <td >
                                        {item?.createdDate}
                                    </td>
                                    {/* <td>
                                        <button className='btn btn-xs'>Update User</button>
                                    </td> */}
                                    {/* <td className='flex items-center'>
                                        <span className='ml-4 cursor-pointer text-[#e63a27] text-2xl'><HiTrash></HiTrash></span>
                                    </td> */}
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <dialog id="my_modal_1" className="modal w-[400px] mx-auto rounded">
                <form onSubmit={(e) => createNewStatus(e)} method="dialog" className="modal-box">
                    <label className='label text-sm' htmlFor="introducedBy">Status Name:</label>
                    <input id='name' name='name' className='w-full input input-sm input-bordered' type="text" />
                    <div className="modal-action flex justify-between">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="btn btn-sm text-xs bg-rsf text-[#fff]">Close</button>
                        <button type='submit' className="btn btn-sm text-xs bg-rsf text-[#fff]">Submit</button>
                    </div>
                </form>
            </dialog>
        </div>
    )
}

export default AllStatus