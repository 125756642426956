import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import 'firebase/auth';
import 'firebase/firestore';
import { doc, setDoc } from "firebase/firestore";
import { db, storage } from '../../firebase/firebase.config';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";


const UpdateUser = () => {
    const { user: currentUser, updateUserInfo } = useContext(AuthContext);
    const [name, setName] = useState('');
    const [photo, setPhoto] = useState(null);
    const [loading, setLoading] = useState(false);


    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handlePhotoChange = (e) => {
        // Handle photo upload/change
        setPhoto(e.target.files[0]);
    };

    // const updateUser = (e) => {
    //     e.preventDefault();


    //     if (name !== '') {
    //         currentUser.updateProfile({
    //             displayName: name
    //         }).then(() => {

    //         }).catch((error) => {

    //         });
    //     }


    //     if (photo !== null) {
    //         const storageRef = storage().ref().child(`userPhotos/${currentUser.uid}/${photo.name}`);
    //         storageRef.put(photo).then(() => {

    //             storageRef.getDownloadURL().then((url) => {
    //                 currentUser.updateProfile({
    //                     photoURL: url
    //                 }).then(() => {

    //                     setDoc(doc(db, "users", currentUser.uid), {
    //                         uid: currentUser.uid,
    //                         name,
    //                         email: currentUser.email,
    //                         photoURL: url,
    //                     });
    //                     setDoc(doc(db, "userChats", currentUser.uid), {});
    //                 }).catch((error) => {

    //                 });
    //             });
    //         }).catch((error) => {
    //         });
    //     }
    // };

    const updateUser = async (e) => {
        e.preventDefault();
        setLoading(true)

        try {
            if (name !== '') {
                updateUserInfo({
                    displayName: name
                }).then(() => {
                    // Handle success after updating display name
                });
            }

            if (photo !== null) {
                const storageRef = ref(storage, `userPhotos/${currentUser.uid}/${photo.name}`);
                await uploadBytes(storageRef, photo);
                const downloadURL = await getDownloadURL(storageRef);

                await updateUserInfo({
                    photoURL: downloadURL
                }).then(() => {
                    // Handle success after updating photoURL
                });

                const userDocRef = doc(db, "users", currentUser.uid);
                await setDoc(userDocRef, {
                    uid: currentUser.uid,
                    displayName: name || currentUser.displayName,
                    email: currentUser.email,
                    photoURL: downloadURL,
                });

                const userChatsDocRef = doc(db, "userChats", currentUser.uid);
                await setDoc(userChatsDocRef, {});

                setLoading(false)
                // Log a message after the updateUser function is completed
                console.log('User information update completed');
            }
        } catch (error) {
            // Handle any errors here
            setLoading(false)
            console.error('Error:', error);
        }
    };




    return (
        <div className='h-[80vh] flex justify-center items-center'>
            <form onSubmit={updateUser} method="dialog" className="modal-box">
                <label className='label text-sm' htmlFor="name">Name:</label>
                <input id='name' className='input w-full' value={name} onChange={handleNameChange} placeholder='Type User Name / Company Here' type="text" required />

                <label className='label text-sm' htmlFor="photo">Photo:</label>
                <input type="file" id='photo' onChange={handlePhotoChange} className="file-input file-input-bordered w-full" required />

                <div className="modal-action">
                    {
                        loading ? <button className="btn">
                            <span className="loading loading-spinner"></span>
                            loading
                        </button> : <button type='submit' className="button">Update</button>
                    }
                </div>
            </form>
        </div>
    )
}

export default UpdateUser
