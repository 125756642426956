import React, { useContext, useEffect, useState } from 'react'
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from '../../firebase/firebase.config';
import { useParams } from 'react-router-dom';
import EditBusinessLoan from '../../components/EditLoan/EditBusinessLoan';
import { AuthContext } from '../../context/AuthContext';

const EditLoanPage = () => {
    const { user } = useContext(AuthContext);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [loanData, setLoanData] = useState({});
    const [pdflist, setPdflist] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [statusStep, setStatusStep] = useState(1);

    useEffect(() => {
        setLoading(true)
        const url = `${process.env.REACT_APP_SERVER_URL}/loan-request/${id}`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                setLoanData(data);
                setLoading(false);
                if (data?.status === "Initial Review") {
                    setStatusStep(1);
                }
                else if (data?.status === "Additional Documents") {
                    setStatusStep(2);
                }
                else if (data?.status === "Golden Data") {
                    setStatusStep(3);
                }
                else if (data?.status === "The Great Takeover") {
                    setStatusStep(4);
                }
                else if (data?.status === "Pre-Underwriting") {
                    setStatusStep(5);
                }
                else if (data?.status === "Pre-Offer") {
                    setStatusStep(6);
                }
                else if (data?.status === "Underwriting") {
                    setStatusStep(7);
                }
                else if (data?.status === "Extra docs ob") {
                    setStatusStep(8);
                }
                else if (data?.status === "Offer") {
                    setStatusStep(9);
                }
                else if (data?.status === "Awaiting Offer") {
                    setStatusStep(10);
                }
                else if (data?.status === "Contract Sent") {
                    setStatusStep(11);
                }
                else if (data?.status === "Awaiting Funding") {
                    setStatusStep(12);
                }
                else if (data?.status === "Funded This Month") {
                    setStatusStep(13);
                }
                else if (data?.status === "Funded") {
                    setStatusStep(14);
                }
                else if (data?.status === "Cancelled") {
                    setStatusStep(15);
                }
            })
            .catch(error => {
                console.error(error);
            });

        const pdfsRef = ref(storage, `pdf/${id}`);
        listAll(pdfsRef)
            .then((result) => {
                setPdflist(result.items)
            })
            .catch((error) => {
                console.error(error);
            });
    }, [id, refresh, user?.email])

    const handlePdfLinkClick = (e, item) => {
        e.preventDefault(); // Prevent the default behavior of the anchor element

        getDownloadURL(item)
            .then((url) => {
                // Once you have the URL, open it in a new tab/window
                window.open(url, '_blank');
            })
            .catch((error) => {
                console.error('Error getting download URL:', error);
            });
    };

    if (loanData.type === "My Cash Line" || loanData.type === "Bizcap" || loanData.type === "Grants" || loanData.type === "MCA" || loanData.type === "Renewal MCA" || loanData.type === "New Lead" || loanData.type === "Invoice" || loanData.type === "Business Loan" || loanData.type === "Renewal Loan" || loanData.type === "YouLend" || loanData.type === "Liberis" || loanData.type === "365" || loanData.type === "Seneca" || loanData.type === "Funding Alt" || loanData.type === "Swiftfund" || loanData.type === "Maxcap" || loanData.type === "Funding Circle" || loanData.type === "Sedge" || loanData.type === "IWOCA" || loanData.type === "Capify") {
        return <EditBusinessLoan
            statusStep={statusStep}
            setRefresh={setRefresh}
            refresh={refresh}
            loanData={loanData}
            handlePdfLinkClick={handlePdfLinkClick}
            pdflist={pdflist}
            loading={loading}
            setLoading={setLoading}
        />
    }
}

export default EditLoanPage
