import React from 'react'
import { RxCross2 } from "react-icons/rx";

const Popup = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-10 w-[700px] relative">
                <button
                    className="absolute top-2 right-2 p-2 bg-[#e63a27] text-white rounded-full"
                    onClick={onClose}
                >
                    <RxCross2 />
                </button>
                {children}
            </div>
        </div>
    )
}

export default Popup