import React, { useEffect, useState } from 'react'

const FundingRequest = () => {
    const [fundingRequests, setFundingRequests] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/funding-request/GETALL`)
            .then(response => response.json())
            .then(data => {
                // Call a function to handle the loan data
                setFundingRequests(data);
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle the error or display an error message
            });
    }, [])

    const rows = ["businessName", "annualTurnover", "takesCardPayments", "invoiceType", "govermentGrantsType", "interestedProduct", "homeAddress"];
    return (
        <div className='mt-10 mr-10'>
            <div style={{ boxShadow: "0 20px 27px 0 rgba(0, 0, 0, 0.05)" }} className='mt-5 rounded-lg bg-[#fff]'>
                <div className="overflow-x-auto">
                    <table className="table">
                        {/* head */}
                        <thead>
                            <tr>
                                {
                                    rows.map((item, i) => <th key={i} scope="col" className='uppercase'>
                                        {item}
                                    </th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                fundingRequests.map((item, i) => <tr key={i}>
                                    <th scope="row">
                                        {item["businessName"]}
                                    </th>
                                    <td >
                                        {item["annualTurnover"]}
                                    </td>
                                    <td >
                                        {item["takesCardPayments"]}
                                    </td>
                                    <td >
                                        {item["invoiceType"]}
                                    </td>
                                    <td >
                                        {item["govermentGrantsType"]}
                                    </td>
                                    <td >
                                        {item["interestedProduct"]}
                                    </td>
                                    <td >
                                        {item["homeAddress"]}
                                    </td>
                                    
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default FundingRequest