import React, { useContext } from 'react'
import Sidenav from '../components/Sidenav/Sidenav'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/Navbar/Navbar'
import { DataContext } from '../context/DataContext';
import { MoonLoader } from 'react-spinners';

function Main() {
    const { uploading } = useContext(DataContext);

    return (
        <div className={`flex bg-base-100`}>
            {
                uploading && <div>
                    <div className='opacity-80 bg-gray-900 w-full h-screen absolute top-0 left-0 z-20 backdrop-blur-lg'></div>
                    <div className='w-full h-screen flex justify-center items-center absolute top-0 left-0 '>
                        <MoonLoader color="#e63a27" size={33} className='mx-auto z-40' />
                    </div>
                </div>
            }
            <Sidenav></Sidenav>
            <div className='w-[calc(100vw-270px)] px-5 pt-5 relative'>
                <Navbar></Navbar>
                <Outlet></Outlet>
            </div>

        </div>
    )
}

export default Main