import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'

const CustomerProfile = () => {
    const {deleteUserData} = useContext(AuthContext);
  return (
    <div className='w-full flex justify-center p-10'>
        <button onClick={deleteUserData} className='button'>Delete Account</button>
    </div>
  )
}

export default CustomerProfile