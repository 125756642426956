import React from 'react'

const ViewLoan = ({ item }) => {
    // const renderKeyValuePairs = () => {
    //     const pairs = [];
    //     for (const key in item) {
    //       if (item.hasOwnProperty(key)) {
    //         pairs.push(
    //           <div
    //             key={key}
    //             className="flex items-center text-sm justify-between py-2 px-4 border-b border-gray-50"
    //           >
    //             <span className="text-gray-600">{key}</span>
    //             <span className="text-gray-400 font-semibold">{item[key]}</span>
    //           </div>
    //         );
    //       }
    //     }
    //     return pairs;
    //   };

    const renderKeyValuePairs = () => {
        const pairs = [];
        for (const key in item) {
            if (item.hasOwnProperty(key)) {
                if (key === "business-statement") {
                    // For "business-statement" key, render the anchor tag with the value as the link
                    pairs.push(
                        <div
                            key={key}
                            className="flex items-center justify-between py-2 px-4  border-neutral-content"
                        >
                            <span className="text-gray-600">{key}</span>
                            <a
                                href={item[key]} // Assuming item[key] holds the URL for the PDF
                                className="btn btn-sm text-xs"
                                target='_blank'
                                download
                            >
                                Download PDF
                            </a>
                        </div>
                    );
                } else {
                    // For other keys, render regular key-value pairs
                    pairs.push(
                        <div
                            key={key}
                            className="flex items-center justify-between py-3 px-4  border-neutral-content"
                        >
                            <span className="text-gray-600">{key}</span>
                            <span className="text-gray-600 font-semibold">{item[key]}</span>
                        </div>
                    );
                }
            }
        }
        return pairs;
    };
    return (
        <div className="w-full border rounded-lg overflow-hidden">
            <div className="bg-gray-100 text-gray-700 text-lg font-semibold px-6 py-4">
                Loan Request Details Details
            </div>
            <div className="divide-y text-sm divide-gray-300">{renderKeyValuePairs()}</div>
        </div>
    )
}

export default ViewLoan