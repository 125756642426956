import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext';
import { BarLoader, PacmanLoader } from 'react-spinners';

function Login() {
    const navigate = useNavigate();
    const [wrongAccess, setWrongAccess] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [error, setError] = useState(false);
    const { signIn, user, setLoading, logOut, loading, adminData, setAdminData, setTokenFetching } = useContext(AuthContext);
    const [tokenLoading, setTokenLoading] = useState(true);
    const token = localStorage.getItem('token');


    useEffect(() => {
        const fetchData = async () => {
            if (!loading && user?.uid && !loginLoading) {
                try {
                    const tokenResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/token-varification`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
                        body: JSON.stringify({ email: user?.email })
                    });
                    const tokenData = await tokenResponse.json();

                    if (tokenData === false) {
                        logOut();
                    } else {
                        const adminResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/verify`, {
                            method: 'POST',
                            headers: { 'content-type': 'application/json' },
                            body: JSON.stringify({ email: user?.email })
                        });
                        const adminData = await adminResponse.json();
                        setAdminData(adminData);
                        if (adminData?.role === "super-admin" || adminData?.role === "admin") {
                            setTokenLoading(false);
                            navigate("/admin/kanaban");
                        } else {
                            setTokenLoading(false);
                            logOut();
                        }
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setAdminData(null);
                    setTokenLoading(false);
                }
            } else if (!loading && !user) {
                setTokenLoading(false);
            }
        };

        fetchData();

    }, [loading, logOut, setAdminData, token]);

    const loginHandler = (event) => {
        setLoginLoading(true);
        setTokenFetching(true);
        event.preventDefault();
        const email = event.target.email.value;
        const password = event.target.password.value;

        signIn(email, password)
            .then(userCredential => {
                fetch(`${process.env.REACT_APP_SERVER_URL}/verify`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ email: userCredential.user.email })
                })
                    .then(res => res.json())
                    .then(data => {
                        fetch(`${process.env.REACT_APP_SERVER_URL}/jwt`, {
                            method: 'POST',
                            headers: {
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ email: userCredential.user.email, role: data.role })
                        })
                            .then(res => res.json())
                            .then(data => {
                                setError(false);
                                localStorage.setItem('token', data.token);
                                setTokenFetching(false)
                                setLoginLoading(false);
                                navigate("/admin/kanaban");
                            })
                            .catch(error => {
                                console.log(error.error);
                                setLoginLoading(false);
                            })
                    })
                    .catch(error => {
                        console.log(error.error);
                        setError(true);
                        setLoginLoading(false);
                    })
            })
            .catch(error => {
                console.log(error);
                setWrongAccess(true)
                setError(true);
                setLoginLoading(false);
            })
        event.target.reset();
    }

    // useEffect(() => {
    //     if (!loading && adminData) {
    //         navigate("/admin/kanaban");
    //     }
    // }, [loading, adminData, navigate]);

    if (loading || tokenLoading) {
        return <div className='font-bold text-4xl flex h-screen bg-base-100 justify-center items-center'>
            <BarLoader color="#e63a27" />
        </div>
    }

    return (
        <div>
            <div className='flex h-screen justify-center bg-base-100 overflow-x-hidden'>
                <div className='basis-1/4  flex h-screen items-center '>
                    <div className='basis-full'>
                        <div className='w-[80%]'>
                            <div className='mb-[30px]'>
                                <div>
                                    <h3 className='text-4xl font-bold text-primary'>Admin Login</h3>
                                </div>
                                <p>Enter your email and password to sign in</p>
                                {
                                    wrongAccess ? <div className="alert alert-error bg-[#e63a27] text-white rounded-xl text-sm mt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                        <span>Your username or password is incorrect. Please try again.</span>
                                    </div> : <></>
                                }
                            </div>
                            <div>
                                <form onSubmit={loginHandler}>
                                    <div>
                                        <label className='block text-sm font-medium mb-3'>Email</label>
                                        <div className='custom-input-div-rsf'>
                                            <input className='custom-input-rsf' type="email" name='email' required />
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <label className='block text-sm font-medium mb-3'>Password</label>
                                        <div className='custom-input-div-rsf'>
                                            <input className='custom-input-rsf' type="password" name='password' required />
                                        </div>
                                    </div>
                                    <div>
                                        {/* <button className='custom-rsf-btn mt-[32px] bg-1'>SIGN IN</button> */}
                                        {/* <button type='submit' className='custom-rsf-btn mt-[32px] bg-2 w-full'>SIGN IN</button> */}
                                        <button type='submit' className='button mt-[32px] w-full'>SIGN IN</button>
                                        {/* <button className='custom-rsf-btn mt-[32px] bg-3'>SIGN IN</button> */}
                                    </div>
                                    <div className='text-sm mt-4 text-center text-[#67748E]'>
                                        Login as a Customer? <Link to="/" className='color-rsf font-semibold'> Click Here</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-login-page-img-box basis-[42%]'>
                    <div className='custom-login-page-img-inner-box'>
                        <div className='custom-login-page-img'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login