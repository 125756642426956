import React, { useContext } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { PacmanLoader } from 'react-spinners';
import { AuthContext } from '../../context/AuthContext';


const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const { user, loading, logOut } = useContext(AuthContext);
    const location = useLocation();
    const token = localStorage.getItem('token');

    navigate('/admin');

    if (user && !loading && token !== null) {
        fetch(`${process.env.REACT_APP_SERVER_URL}/token-varification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ email: user?.email })
        })
            .then(response => response.json())
            .then(data => {
                if (data === false) {
                    return logOut();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }


    if (loading) {
        return <div className='font-bold text-4xl flex h-screen justify-center items-center'>
            <PacmanLoader color="#e63a27" />
        </div>
    }

    if (user) {
        return children;
    }

    return <Navigate to="/" state={{ from: location }} replace></Navigate>;
};

export default PrivateRoute;