import React from 'react'
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs'

const LoanPdfList = ({ pdflist, handlePdfLinkClick }) => {

    return (
        <div>
            {
                pdflist.map((item, i) => {
                    return <a key={i} href="#" onClick={(e) => handlePdfLinkClick(e, item)} className='mb-2 block' target='_blank'>
                        <div className="alert rounded-lg bg-gray-200 py-3">
                            <BsFillFileEarmarkPdfFill />
                            <span>{item?.name}</span>
                        </div>
                    </a>
                })
            }
        </div>
    )
}

export default LoanPdfList