import React, { useState } from 'react'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../firebase/firebase.config';
import { v4 } from "uuid";

const EditLoanCustomer = ({ item, closeModal, setRefresh, refresh }) => {
    const [pdfUpload, setPdfUpload] = useState(null);
    const [eya, setEyaUpload] = useState(null);
    const [leatestManagementAccoutns, setLeatestManagementAccoutns] = useState(null);
    const [salesLedger, setSalesLedger] = useState(null);
    const loantype = item['type'];

    const logs = (e, f) => {
        e.preventDefault()
        if (loantype === "Short Term Loan") {
            if (pdfUpload === null) {
                const loanData = {
                    "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                    "business-name": e?.target?.businessName?.value,
                    "business-contact-name": e?.target?.contactName?.value,
                    "business-phone": e?.target?.phone?.value,
                    "business-email": e?.target?.email?.value,
                    "business-address": e?.target?.businessAddress?.value,
                    "business-chip": e?.target?.chipPin?.value,
                    "business-turnover": e?.target?.businessTurnover?.value,
                    "business-invoice": e?.target?.invoice?.value,
                    "business-notes": e?.target?.notes?.value,
                    "required-amount": e?.target?.requiredAmount?.value,
                    "funding-manager": e?.target?.fundingManager?.value,
                    "funded-amount": e?.target?.fundedAmount?.value,
                    "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                    "type": loantype
                };
                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(loanData)
                })
                    .then(response => response.json())
                    .then(result => {
                        // loanData._id = result?.insertedId;
                        setRefresh(!refresh);
                        closeModal("editLoan");
                        return;
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
            else {
                const pdfRef = ref(storage, `rsfPDF/${pdfUpload.name + v4()}`);
                uploadBytes(pdfRef, pdfUpload)
                    .then((res) => {
                        const fileRef = ref(storage, res.metadata.fullPath);
                        getDownloadURL(fileRef)
                            .then((url) => {
                                const loanData = {
                                    "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                                    "business-name": e?.target?.businessName?.value,
                                    "business-contact-name": e?.target?.contactName?.value,
                                    "business-phone": e?.target?.phone?.value,
                                    "business-email": e?.target?.email?.value,
                                    "business-address": e?.target?.businessAddress?.value,
                                    "business-chip": e?.target?.chipPin?.value,
                                    "business-turnover": e?.target?.businessTurnover?.value,
                                    "business-invoice": e?.target?.invoice?.value,
                                    "business-statement": url,
                                    "business-notes": e?.target?.notes?.value,
                                    "required-amount": e?.target?.requiredAmount?.value,
                                    "funding-manager": e?.target?.fundingManager?.value,
                                    "funded-amount": e?.target?.fundedAmount?.value,
                                    "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                                    "type": loantype,
                                };
                                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(loanData)
                                })
                                    .then(response => response.json())
                                    .then(result => {
                                        setRefresh(!refresh);
                                        closeModal("editLoan");
                                    })
                                    .catch(error => {
                                        console.error('Error:', error);
                                    });
                            })
                            .catch((error) => {
                                console.error("Error getting download URL:", error);
                            });
                    })
            }
        }
        else if (loantype === "Long Term Loan") {
            if (pdfUpload == null && eya == null && leatestManagementAccoutns == null) {
                const loanData = {
                    "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                    "business-name": e?.target?.businessName?.value,
                    "business-contact-name": e?.target?.contactName?.value,
                    "business-phone": e?.target?.phone?.value,
                    "business-email": e?.target?.email?.value,
                    "business-address": e?.target?.businessAddress?.value,
                    "business-turnover": e?.target?.businessTurnover?.value,
                    "business-invoice": e?.target?.invoice?.value,
                    "business-notes": e?.target?.notes?.value,
                    "required-amount": e?.target?.requiredAmount?.value,
                    "funding-manager": e?.target?.fundingManager?.value,
                    "funded-amount": e?.target?.fundedAmount?.value,
                    "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                    "type": loantype,
                    "createdDate": new Date().toISOString()
                };
                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(loanData)
                })
                    .then(response => response.json())
                    .then(result => {
                        setRefresh(!refresh);
                        closeModal("editLoan");
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
            else if (pdfUpload !== null && eya == null && leatestManagementAccoutns == null) {
                const pdfRef = ref(storage, `rsfPDF/${pdfUpload.name + v4()}`);
                uploadBytes(pdfRef, pdfUpload)
                    .then((res) => {
                        const fileRef = ref(storage, res.metadata.fullPath);
                        getDownloadURL(fileRef)
                            .then((pdfURL) => {
                                const loanData = {
                                    "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                                    "business-name": e?.target?.businessName?.value,
                                    "business-contact-name": e?.target?.contactName?.value,
                                    "business-phone": e?.target?.phone?.value,
                                    "business-email": e?.target?.email?.value,
                                    "business-address": e?.target?.businessAddress?.value,
                                    "business-turnover": e?.target?.businessTurnover?.value,
                                    "business-invoice": e?.target?.invoice?.value,
                                    "business-statement": pdfURL,
                                    "business-notes": e?.target?.notes?.value,
                                    "required-amount": e?.target?.requiredAmount?.value,
                                    "funding-manager": e?.target?.fundingManager?.value,
                                    "funded-amount": e?.target?.fundedAmount?.value,
                                    "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                                    "type": loantype,
                                    "createdDate": new Date().toISOString()
                                };
                                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(loanData)
                                })
                                    .then(response => response.json())
                                    .then(result => {
                                        setPdfUpload(null);
                                        setRefresh(!refresh);
                                        closeModal("editLoan");
                                    })
                                    .catch(error => {
                                        console.error('Error:', error);
                                    });

                            })
                            .catch((error) => {
                                console.error("Error getting download URL:", error);
                            });
                    })
            }
            else if (pdfUpload == null && eya !== null && leatestManagementAccoutns == null) {
                const eyaRef = ref(storage, `rsfEYA/${eya.name + v4()}`);
                uploadBytes(eyaRef, eya)
                    .then((res) => {
                        const fileRef = ref(storage, res.metadata.fullPath);
                        getDownloadURL(fileRef)
                            .then((eyaURL) => {
                                const loanData = {
                                    "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                                    "business-name": e?.target?.businessName?.value,
                                    "business-contact-name": e?.target?.contactName?.value,
                                    "business-phone": e?.target?.phone?.value,
                                    "business-email": e?.target?.email?.value,
                                    "business-address": e?.target?.businessAddress?.value,
                                    "business-turnover": e?.target?.businessTurnover?.value,
                                    "business-invoice": e?.target?.invoice?.value,
                                    "eya": eyaURL,
                                    "business-notes": e?.target?.notes?.value,
                                    "required-amount": e?.target?.requiredAmount?.value,
                                    "funding-manager": e?.target?.fundingManager?.value,
                                    "funded-amount": e?.target?.fundedAmount?.value,
                                    "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                                    "type": loantype,
                                };
                                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(loanData)
                                })
                                    .then(response => response.json())
                                    .then(result => {
                                        setRefresh(!refresh);
                                        closeModal("editLoan");
                                    })
                                    .catch(error => {
                                        console.error('Error:', error);
                                    });

                            })
                            .catch((error) => {
                                console.error("Error getting download URL:", error);
                            });
                    })
            }
            else if (pdfUpload == null && eya == null && leatestManagementAccoutns !== null) {
                const lmaRef = ref(storage, `rsfLMA/${leatestManagementAccoutns.name + v4()}`);
                uploadBytes(lmaRef, leatestManagementAccoutns)
                    .then((res) => {
                        const fileRef = ref(storage, res.metadata.fullPath);
                        getDownloadURL(fileRef)
                            .then((lmaURL) => {
                                const loanData = {
                                    "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                                    "business-name": e?.target?.businessName?.value,
                                    "business-contact-name": e?.target?.contactName?.value,
                                    "business-phone": e?.target?.phone?.value,
                                    "business-email": e?.target?.email?.value,
                                    "business-address": e?.target?.businessAddress?.value,
                                    "business-turnover": e?.target?.businessTurnover?.value,
                                    "business-invoice": e?.target?.invoice?.value,
                                    "leatest-management-accoutns": lmaURL,
                                    "business-notes": e?.target?.notes?.value,
                                    "required-amount": e?.target?.requiredAmount?.value,
                                    "funding-manager": e?.target?.fundingManager?.value,
                                    "funded-amount": e?.target?.fundedAmount?.value,
                                    "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                                    "type": loantype,
                                };
                                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(loanData)
                                })
                                    .then(response => response.json())
                                    .then(result => {
                                        setRefresh(!refresh);
                                        closeModal("editLoan");
                                    })
                                    .catch(error => {
                                        console.error('Error:', error);
                                    });

                            })
                            .catch((error) => {
                                console.error("Error getting download URL:", error);
                            });
                    })
            }
            else if (pdfUpload !== null && eya !== null && leatestManagementAccoutns == null) {
                const pdfRef = ref(storage, `rsfPDF/${pdfUpload.name + v4()}`);
                const eyaRef = ref(storage, `rsfEYA/${eya.name + v4()}`);
                uploadBytes(pdfRef, pdfUpload)
                    .then((res) => {
                        getDownloadURL(ref(storage, res.metadata.fullPath))
                            .then((pdfURL) => {
                                uploadBytes(eyaRef, eya)
                                    .then((eyares) => {
                                        getDownloadURL(ref(storage, eyares.metadata.fullPath))
                                            .then((eyaURL) => {
                                                const loanData = {
                                                    "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                                                    "business-name": e?.target?.businessName?.value,
                                                    "business-contact-name": e?.target?.contactName?.value,
                                                    "business-phone": e?.target?.phone?.value,
                                                    "business-email": e?.target?.email?.value,
                                                    "business-address": e?.target?.businessAddress?.value,
                                                    "business-turnover": e?.target?.businessTurnover?.value,
                                                    "business-invoice": e?.target?.invoice?.value,
                                                    "business-statement": pdfURL,
                                                    "eya": eyaURL,
                                                    "business-notes": e?.target?.notes?.value,
                                                    "required-amount": e?.target?.requiredAmount?.value,
                                                    "funding-manager": e?.target?.fundingManager?.value,
                                                    "funded-amount": e?.target?.fundedAmount?.value,
                                                    "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                                                    "type": loantype,
                                                };
                                                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify(loanData)
                                                })
                                                    .then(response => response.json())
                                                    .then(result => {
                                                        setRefresh(!refresh);
                                                        closeModal("editLoan");
                                                    })
                                                    .catch(error => {
                                                        console.error('Error:', error);
                                                    });
                                            })

                                    })
                            })
                            .catch((error) => {
                                console.error("Error getting download URL:", error);
                            });
                    })
            }
            else if (pdfUpload == null && eya !== null && leatestManagementAccoutns !== null) {
                const lmaRef = ref(storage, `rsfLMA/${leatestManagementAccoutns.name + v4()}`);
                const eyaRef = ref(storage, `rsfEYA/${eya.name + v4()}`);
                uploadBytes(lmaRef, pdfUpload)
                    .then((res) => {
                        getDownloadURL(ref(storage, res.metadata.fullPath))
                            .then((lmaURL) => {
                                uploadBytes(eyaRef, eya)
                                    .then((eyares) => {
                                        getDownloadURL(ref(storage, eyares.metadata.fullPath))
                                            .then((eyaURL) => {
                                                const loanData = {
                                                    "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                                                    "business-name": e?.target?.businessName?.value,
                                                    "business-contact-name": e?.target?.contactName?.value,
                                                    "business-phone": e?.target?.phone?.value,
                                                    "business-email": e?.target?.email?.value,
                                                    "business-address": e?.target?.businessAddress?.value,
                                                    "business-turnover": e?.target?.businessTurnover?.value,
                                                    "business-invoice": e?.target?.invoice?.value,
                                                    "leatest-management-accoutns": lmaURL,
                                                    "eya": eyaURL,
                                                    "business-notes": e?.target?.notes?.value,
                                                    "required-amount": e?.target?.requiredAmount?.value,
                                                    "funding-manager": e?.target?.fundingManager?.value,
                                                    "funded-amount": e?.target?.fundedAmount?.value,
                                                    "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                                                    "type": loantype,
                                                };
                                                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify(loanData)
                                                })
                                                    .then(response => response.json())
                                                    .then(result => {
                                                        setRefresh(!refresh);
                                                        closeModal("editLoan");
                                                    })
                                                    .catch(error => {
                                                        console.error('Error:', error);
                                                    });
                                            })

                                    })
                            })
                            .catch((error) => {
                                console.error("Error getting download URL:", error);
                            });
                    })
            }
            else if (pdfUpload !== null && eya == null && leatestManagementAccoutns !== null) {
                const lmaRef = ref(storage, `rsfLMA/${leatestManagementAccoutns.name + v4()}`);
                const pdfRef = ref(storage, `rsfPDF/${pdfUpload.name + v4()}`);
                uploadBytes(lmaRef, leatestManagementAccoutns)
                    .then((res) => {
                        getDownloadURL(ref(storage, res.metadata.fullPath))
                            .then((lmaURL) => {
                                uploadBytes(pdfRef, pdfUpload)
                                    .then((pdfres) => {
                                        getDownloadURL(ref(storage, pdfres.metadata.fullPath))
                                            .then((pdfURL) => {
                                                const loanData = {
                                                    "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                                                    "business-name": e?.target?.businessName?.value,
                                                    "business-contact-name": e?.target?.contactName?.value,
                                                    "business-phone": e?.target?.phone?.value,
                                                    "business-email": e?.target?.email?.value,
                                                    "business-address": e?.target?.businessAddress?.value,
                                                    "business-turnover": e?.target?.businessTurnover?.value,
                                                    "business-invoice": e?.target?.invoice?.value,
                                                    "leatest-management-accoutns": lmaURL,
                                                    "business-statement": pdfURL,
                                                    "business-notes": e?.target?.notes?.value,
                                                    "required-amount": e?.target?.requiredAmount?.value,
                                                    "funding-manager": e?.target?.fundingManager?.value,
                                                    "funded-amount": e?.target?.fundedAmount?.value,
                                                    "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                                                    "type": loantype,
                                                };
                                                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify(loanData)
                                                })
                                                    .then(response => response.json())
                                                    .then(result => {
                                                        setRefresh(!refresh);
                                                        closeModal("editLoan");
                                                    })
                                                    .catch(error => {
                                                        console.error('Error:', error);
                                                    });
                                            })

                                    })
                            })
                            .catch((error) => {
                                console.error("Error getting download URL:", error);
                            });
                    })
            }
            else if (pdfUpload !== null && eya !== null && leatestManagementAccoutns !== null) {
                const pdfRef = ref(storage, `rsfPDF/${pdfUpload.name + v4()}`);
                const eyaRef = ref(storage, `rsfEYA/${eya.name + v4()}`);
                const lmaRef = ref(storage, `rsfLMA/${leatestManagementAccoutns.name + v4()}`);
                uploadBytes(pdfRef, pdfUpload)
                    .then((res) => {
                        const fileRef = ref(storage, res.metadata.fullPath);
                        getDownloadURL(fileRef)
                            .then((pdfURL) => {
                                uploadBytes(eyaRef, eya)
                                    .then((eyares) => {
                                        getDownloadURL(ref(storage, eyares.metadata.fullPath))
                                            .then((eyaURL) => {
                                                uploadBytes(lmaRef, leatestManagementAccoutns)
                                                    .then((lmares) => {
                                                        getDownloadURL(ref(storage, lmares.metadata.fullPath))
                                                            .then((lmaURL) => {
                                                                const loanData = {
                                                                    "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                                                                    "business-name": e?.target?.businessName?.value,
                                                                    "business-contact-name": e?.target?.contactName?.value,
                                                                    "business-phone": e?.target?.phone?.value,
                                                                    "business-email": e?.target?.email?.value,
                                                                    "business-address": e?.target?.businessAddress?.value,
                                                                    "business-turnover": e?.target?.businessTurnover?.value,
                                                                    "business-invoice": e?.target?.invoice?.value,
                                                                    "business-statement": pdfURL,
                                                                    "eya": eyaURL,
                                                                    "leatest-management-accoutns": lmaURL,
                                                                    "business-notes": e?.target?.notes?.value,
                                                                    "required-amount": e?.target?.requiredAmount?.value,
                                                                    "funding-manager": e?.target?.fundingManager?.value,
                                                                    "funded-amount": e?.target?.fundedAmount?.value,
                                                                    "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                                                                    "type": loantype,
                                                                };
                                                                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                                                                    method: 'POST',
                                                                    headers: {
                                                                        'Content-Type': 'application/json'
                                                                    },
                                                                    body: JSON.stringify(loanData)
                                                                })
                                                                    .then(response => response.json())
                                                                    .then(result => {
                                                                        setRefresh(!refresh);
                                                                        closeModal("editLoan");
                                                                    })
                                                                    .catch(error => {
                                                                        console.error('Error:', error);
                                                                    });
                                                            })
                                                    })
                                            })
                                    })
                            })
                            .catch((error) => {
                                console.error("Error getting download URL:", error);
                            });
                    })
            }
        }
        else if (loantype === "Invoice Finance") {
            if (pdfUpload == null || salesLedger == null) {

            }
            else {
                const pdfRef = ref(storage, `rsfPDF/${pdfUpload.name + v4()}`);
                const salesRef = ref(storage, `salesPDF/${salesLedger.name + v4()}`);
                uploadBytes(pdfRef, pdfUpload)
                    .then((res) => {
                        const fileRef = ref(storage, res.metadata.fullPath);
                        getDownloadURL(fileRef)
                            .then((pdfURL) => {
                                uploadBytes(salesRef, salesLedger)
                                    .then((salesres) => {
                                        getDownloadURL(ref(storage, salesres.metadata.fullPath))
                                            .then((salesURL) => {
                                                const dialog = document.getElementById(f);
                                                const loanData = {
                                                    "business-type": e?.target?.businessType?.value,
                                                    "business-name": e?.target?.businessName?.value,
                                                    "business-contact-name": e?.target?.contactName?.value,
                                                    "business-phone": e?.target?.phone?.value,
                                                    "business-email": e?.target?.email?.value,
                                                    "business-address": e?.target?.businessAddress?.value,
                                                    "business-turnover": e?.target?.businessTurnover?.value,
                                                    "business-invoice": e?.target?.invoice?.value,
                                                    "business-statement": pdfURL,
                                                    "sales-ledger": salesURL,
                                                    "business-notes": e?.target?.notes?.value,
                                                    "required-amount": e?.target?.requiredAmount?.value,
                                                    "user": 'admin',
                                                    "type": loantype,
                                                    "createdDate": new Date().toISOString()
                                                };
                                                fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify(loanData)
                                                })
                                                    .then(response => response.json())
                                                    .then(result => {
                                                        loanData._id = result?.insertedId;
                                                        dialog.close();
                                                    })
                                                    .catch(error => {
                                                        console.error('Error:', error);
                                                    });
                                            })
                                    })
                            })
                            .catch((error) => {
                                console.error("Error getting download URL:", error);
                            });
                    })
            }

        }
        else if (loantype === "Investments") {
            const dialog = document.getElementById(f);
            const loanData = {
                "business-type": e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "business-email": e?.target?.email?.value,
                "trading-duration": e?.target?.tradingTime?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "user": 'admin',
                "type": loantype,
                "createdDate": new Date().toISOString()
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    loanData._id = result?.insertedId;
                    dialog.close();
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    return (
        <div>
            {
                loantype === "Short Term Loan" && <form onSubmit={(e) => logs(e, "stl")} className="rounded-lg">
                    <div className='grid grid-cols-2 gap-3'>
                        <select name='businessType' className="input">
                            <option value="Company Type" disabled selected>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" defaultValue={item['business-name']} className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" defaultValue={item['business-contact-name']} className="input" />
                        <input name='phone' type="text" placeholder="Phone Number" defaultValue={item['business-phone']} className="input" />
                        <input name='email' type="text" placeholder="Email" defaultValue={item['business-email']} className="input" />
                        <input name='businessAddress' type="text" placeholder="Business Address" defaultValue={item['business-address']} className="input" />
                        <input name='chipPin' type="text" placeholder="Chip & Pin monthly" defaultValue={item['business-chip']} className="input" />
                        <input name='businessTurnover' type="text" placeholder="Business Turnover Monthly" defaultValue={item['business-turnover']} className="input" />
                        <input name='invoice' type="text" placeholder="Do they Invoice" defaultValue={item['business-invoice']} className="input" />
                        <input name='requiredAmount' type="text" placeholder="Required Amount" defaultValue={item['required-amount']} className="input" />
                    </div>
                    <label className='label text-sm mt-1'>Bank Statement</label>
                    <input onChange={(e) => { setPdfUpload(e.target.files[0]) }} type="file" className="file-input file-input-bordered w-full" />
                    <input name='notes' type="text" placeholder="Notes" defaultValue={item['business-notes']} className="input w-full mt-3" />
                    <div className='flex gap-3 mt-4'>
                        {/* <button onClick={() => closeModal("stl")} type='button' className='button basis-1/2'>cancel</button> */}
                        <button type='button' onClick={() => closeModal("editLoan")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] !rounded">Close</button>
                        <button type='submit' className='button basis-1/2 !rounded'>submit</button>
                    </div>
                </form>
            }
            {
                loantype === "Long Term Loan" && <form onSubmit={(e) => logs(e, "ltl")} className="rounded-lg">
                    <div className='grid grid-cols-2 gap-3'>
                        <select name='businessType' className="input">
                            <option value="Company Type" disabled selected>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" defaultValue={item['business-name']} className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" defaultValue={item['business-contact-name']} className="input" />
                        <input name='phone' type="text" placeholder="Phone Number" defaultValue={item['business-phone']} className="input" />
                        <input name='email' type="text" placeholder="Email" defaultValue={item['business-email']} className="input" />
                        <input name='businessAddress' type="text" placeholder="Business Address" defaultValue={item['business-address']} className="input" />
                        <input name='businessTurnover' type="text" placeholder="Business Turnover Monthly" defaultValue={item['business-turnover']} className="input" />
                        <input name='invoice' type="text" placeholder="Do they Invoice" defaultValue={item['business-invoice']} className="input" />
                    </div>
                    <input name='requiredAmount' type="text" placeholder="Required Amount" defaultValue={item['required-amount']} className="input w-full mt-3" />

                    <div className='flex gap-3'>
                        <div>
                            <label className='label text-sm'>Bank Statement</label>
                            <input onChange={(e) => { setPdfUpload(e.target.files[0]) }} type="file" className="file-input file-input-sm file-input-bordered w-full" />
                        </div>
                        <div>
                            <label className='label text-sm'>EYA</label>
                            <input onChange={(e) => { setEyaUpload(e.target.files[0]) }} type="file" className="file-input file-input-sm file-input-bordered w-full" />
                        </div>
                        <div>
                            <label className='label text-sm'>Leatest Management Accoutns</label>
                            <input onChange={(e) => { setLeatestManagementAccoutns(e.target.files[0]) }} type="file" className="file-input file-input-sm file-input-bordered w-full" />
                        </div>
                    </div>
                    <input name='notes' type="text" placeholder="Notes" defaultValue={item['business-notes']} className="input w-full mt-3" />
                    <div className='flex gap-3 mt-4'>
                        {/* <button onClick={() => closeModal("stl")} type='button' className='button basis-1/2'>cancel</button> */}
                        <button type='button' onClick={() => closeModal("editLoan")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] !rounded">Close</button>
                        <button type='submit' className='button basis-1/2 !rounded'>submit</button>
                    </div>
                </form>
            }
            {
                loantype === "Invoice Finance" && <form onSubmit={(e) => logs(e, "invoiceF")} method="dialog" className="modal-box p-10 w-[700px] max-w-[700px] mx-auto">
                    <div className='grid grid-cols-2  gap-3'>
                        <select name='businessType' className="input">
                            <option value="" defaultValue>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" className="input" />
                        <input name='phone' type="text" placeholder="Phone Number" className="input" />
                        <input name='email' type="text" placeholder="Email" className="input" />
                        <input name='businessAddress' type="text" placeholder="Business Address" className="input" />
                        <input name='businessTurnover' type="text" placeholder="Business Turnover Monthly" className="input" />
                        <input name='invoice' type="text" placeholder="Do they Invoice" className="input" />
                        <div>
                            <label className='label text-sm'>Bank Statement</label>
                            <input onChange={(e) => { setPdfUpload(e.target.files[0]) }} type="file" className="file-input file-input-bordered file-input-sm w-full" />
                        </div>
                        <div>
                            <label className='label text-sm'>Sales Ledger</label>
                            <input onChange={(e) => { setSalesLedger(e.target.files[0]) }} type="file" className="file-input file-input-bordered file-input-sm w-full" />
                        </div>
                    </div>
                    <input name='requiredAmount' type="text" placeholder="Required Amount" className="input w-full mt-3" />
                    <input name='notes' type="text" placeholder="Notes" className="input w-full mt-3" />
                    <div className='flex gap-3 mt-4'>
                        {/* <button onClick={() => closeModal("")} type='button' className='button basis-1/2'>cancel</button> */}
                        <button type='button' onClick={() => closeModal("editLoan")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                        <button type='submit' className='button basis-1/2'>submit</button>
                    </div>
                </form>
            }
            {
                loantype === "Investments" && <form onSubmit={(e) => logs(e, "invoiceF")} method="dialog">
                    <div className='grid grid-cols-2  gap-3'>
                        <select name='businessType' className="input">
                            <option value="" defaultValue>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" className="input" />
                        <input name='phone' type="text" placeholder="Phone Number" className="input" />
                        <input name='email' type="text" placeholder="Email" className="input" />
                        <input name='tradingTime' type="text" placeholder="How long have they been trading" className="input" />
                    </div>
                    <input name='requiredAmount' type="text" placeholder="Required Amount" className="input w-full mt-3" />
                    <input name='notes' type="text" placeholder="Notes" className="input w-full mt-3" />
                    <div className='flex gap-3 mt-4'>
                        {/* <button onClick={() => closeModal("")} type='button' className='button basis-1/2'>cancel</button> */}
                        <button type='button' onClick={() => closeModal("editLoan")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                        <button type='submit' className='button basis-1/2'>submit</button>
                    </div>
                </form>
            }
        </div>
    )
}

export default EditLoanCustomer
