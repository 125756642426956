import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import sedgelogo from "./../../assets/sedgelogo.png"

const BusinessLoanPDF = ({ loanData }) => {
    Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });

    const styles = StyleSheet.create({
        page: {
            // fontFamily: 'Oswald',
            padding: 60,
        },
        topbar: {
            flexDirection: 'row',
            alignItems: 'bottom',
            justifyContent: 'space-between',
            marginBottom: 30,
        },
        topside: {
            flexDirection: 'row',
            alignItems: 'center',
            width: '50%'
        },
        image: {
            width: '80%'
        },
        header: {
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 10,
            marginTop: 10,
        },
        table: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 5,
        },
        cellone: {
            width: '35%',
        },
        celltwo: {
            width: '65%',
        },
        label: {
            fontSize: 14,
            fontWeight: 'bold',
            marginBottom: 5,
            color: '#333',
        },
        title: {
            fontSize: 18,
            fontWeight: 'bold',
            color: '#000',
            marginBottom: 10,
            marginTop: 20
        },
        value: {
            fontSize: 13
        },
    });

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.topbar}>
                    <View style={styles.topside}>
                        <Image style={styles.image} src={sedgelogo} />
                    </View>
                    <View style={styles.topside}>
                        <Text style={styles.header}>Business Loan Application</Text>
                    </View>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.title}>The Business</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Business Name:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-name"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Business Address:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-address"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Ave. Monthly Turnover:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>£{parseFloat(loanData["business-turnover"]).toLocaleString()}</Text>
                        {/* <Text style={styles.value}>£{loanData["business-turnover"]}</Text> */}
                    </View>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.title}>The Owner</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Owner Name:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-contact-name"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>DOB:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["dateOfBirth"] ? loanData["dateOfBirth"].split('-').reverse().join('/') : ''}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Home Address:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["home-address"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Phone Number:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-phone"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Email:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-email"]}</Text>
                    </View>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.title}>The Requirements</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Loan Amount Required:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>£{parseFloat(loanData["required-amount"]).toLocaleString()}</Text>
                        {/* <Text style={styles.value}>£{loanData["required-amount"]}</Text> */}
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Purpose of Loan:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["loan-purpose"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Homeowner / Renting:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["home-owner"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Underwriting Notes:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-notes"]}</Text>
                    </View>
                </View>
            </Page>


            {/* <View style={styles.cell}>
                <Text style={styles.label}>Owner Name: <Text style={styles.value}>{loanData["business-contact-name"]}</Text></Text>

            </View>
            <View style={styles.cell}>
                <Text style={styles.label}>Business Address: <Text style={styles.value}>{loanData["business-address"]}</Text></Text>

            </View>
            <View style={styles.cell}>
                <Text style={styles.label}>DOB: <Text style={styles.value}>{loanData["dateOfBirth"]}</Text></Text>

            </View>
            <View style={styles.cell}>
                <Text style={styles.label}>Home Address: <Text style={styles.value}>{loanData["home-address"]}</Text></Text>

            </View>
            <View style={styles.cell}>
                <Text style={styles.label}>Email: <Text style={styles.value}>{loanData["business-email"]}</Text></Text>

            </View>
            <View style={styles.cell}>
                <Text style={styles.label}>Average Monthly Turnover: <Text style={styles.value}>{loanData["business-turnover"]}</Text></Text>

            </View>
            <View style={styles.cell}>
                <Text style={styles.label}>Mobile Number: <Text style={styles.value}>{loanData["business-phone"]}</Text></Text>

            </View>
            <View style={styles.cell}>
                <Text style={styles.label}>Loan Amount Required: <Text style={styles.value}>£{loanData["required-amount"]}</Text></Text>

            </View>
            <View style={styles.cell}>
                <Text style={styles.label}>Purpose of Loan: <Text style={styles.value}>{loanData["loan-purpose"]}</Text></Text>
            </View>
            <View style={styles.cell}>
                <Text style={styles.label}>Homeowner / Renting: <Text style={styles.value}>{loanData["home-owner"]}</Text></Text>

            </View>
            <View style={styles.cell}>
                <Text style={styles.label}>Notes for Lender: <Text style={styles.value}>{loanData["lender"]}</Text></Text>
            </View> */}
        </Document>
    );
};

export default BusinessLoanPDF;
