import { createContext, useEffect, useRef, useState } from "react";
import { createUserWithEmailAndPassword, deleteUser, getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { app, firebaseRrealTmeDataBase } from "../firebase/firebase.config";
import { getDatabase, ref, onValue, onDisconnect, set, serverTimestamp } from 'firebase/database';
import { getFirestore, doc, setDoc, serverTimestamp as firestoreServerTimestamp } from 'firebase/firestore';

export const AuthContext = createContext({});
const auth = getAuth(app);

const AuthProvider = ({ children }) => {
    const [user, setuser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [adminData, setAdminData] = useState({});
    const [country, setCountry] = useState("£");
    const [tokenFetching, setTokenFetching] = useState(false);

    const createAccount = (email, password) => {
        setLoading(true);
        return createUserWithEmailAndPassword(auth, email, password)
    }

    const resetPasswordRequest = (email) => {
        setLoading(true);
        return sendPasswordResetEmail(auth, email)
    }

    const updateUserInfo = profile => {
        return updateProfile(auth.currentUser, profile)
    }

    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const deleteUserData = () => {
        deleteUser(auth.currentUser)
            .then(() => {
                window.location.href = '/';
            }).catch((error) => { });
    }

    const logOut = () => {
        setLoading(true);
        console.log('logout');
        signOut(auth).then(() => {
            localStorage.removeItem('token');
            setuser(null);
            setAdminData(null);
            setLoading(false);
        }).catch((error) => {
            // An error happened.
            setLoading(false);
            console.error(error);
        });
    }

    // Your logout function

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, currentUser => {
            if (currentUser?.uid) {
                setuser(currentUser);
                setLoading(false);
            } else {
                setuser(null);
                setAdminData(null);
                setLoading(false);
            }
        })
        return () => {
            return unsubscribe();
        }
    }, [])

    useEffect(() => {
        const auth = getAuth();
        const database = getDatabase();
        const firestore = getFirestore();

        const handleUserStatusChange = (user) => {
            if (user) {
                const uid = user.uid;
                const userStatusDatabaseRef = ref(database, `/adminUsers/${uid}`);
                const userStatusFirestoreRef = doc(firestore, `/status/${uid}`);

                const isOfflineForDatabase = {
                    state: 'offline',
                    last_changed: serverTimestamp(),
                    email: user?.email,
                };

                const isOnlineForDatabase = {
                    state: 'online',
                    last_changed: serverTimestamp(),
                    email: user?.email,
                };

                const isOfflineForFirestore = {
                    state: 'offline',
                    last_changed: firestoreServerTimestamp(),
                    email: user?.email,
                };

                const isOnlineForFirestore = {
                    state: 'online',
                    last_changed: firestoreServerTimestamp(),
                    email: user?.email,
                };

                const connectedRef = ref(database, '.info/connected');

                onValue(connectedRef, (snapshot) => {
                    if (snapshot.val() === false) {
                        // Firestore offline update
                        setDoc(userStatusFirestoreRef, isOfflineForFirestore)
                            .then(() => console.log('Firestore set to offline'))
                            .catch((error) => console.error('Firestore offline set error:', error));
                        return;
                    }

                    onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase)
                        .then(() => {
                            set(userStatusDatabaseRef, isOnlineForDatabase)
                                .then(() => console.log('Realtime Database set to online'))
                                .catch((error) => console.error('Realtime Database online set error:', error));

                            // Firestore online update
                            setDoc(userStatusFirestoreRef, isOnlineForFirestore)
                                .then(() => console.log('Firestore set to online'))
                                .catch((error) => console.error('Firestore online set error:', error));
                        })
                        .catch((error) => console.error('onDisconnect set error:', error));
                });
            }
        };

        const unsubscribe = onAuthStateChanged(auth, handleUserStatusChange);

        return () => {
            unsubscribe();
        };
    }, []);


    const authInfo = {
        createAccount,
        updateUserInfo,
        user,
        loading,
        setLoading,
        signIn,
        logOut,
        setCountry,
        country,
        deleteUserData,
        adminData,
        setAdminData,
        resetPasswordRequest,
        setTokenFetching,
        // adminUserPresenceData
    }

    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;